/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload , Face} from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Our Products"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink}>
              Silica Gel Bags
            </Link>,
            <a
             /* href="https://creativetimofficial.github.io/material-kit-react/#/documentation?ref=mkr-navbar"*/
             href="/"
              target="_blank"
              className={classes.dropdownLink}
            >
              Pharma Plastic Bags
            </a>
          ]}
        />
      </ListItem>

     
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Call Us"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
           <Button
            color="transparent"
            
            className={classes.navLink}
          >
            <i className="fab fa fa-phone-square"/>(+92) 0300 2118356
          </Button> 
          
        </Tooltip>
      
        
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="instagram-facebook"
          title="Link us on Facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
           <Button
            color="transparent"
            href="https://www.facebook.com/Nafees.silica.gel/"
            target="_blank"
            className={classes.navLink}
          >
            <i className="fab fa-facebook"/>
          </Button> 
          
        </Tooltip>
      
        
      </ListItem>

     
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
