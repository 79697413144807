import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import image1 from 'assets/img/products/1.jpeg'
import image2 from 'assets/img/products/2.jpeg'
import image3 from 'assets/img/products/3.jpeg'
import image4 from 'assets/img/products/4.jpeg'
import image5 from 'assets/img/products/5.jpeg'
import image6 from 'assets/img/products/6.jpeg'
import image7 from 'assets/img/products/7.jpeg'
import image8 from 'assets/img/products/8.jpeg'
import image9 from 'assets/img/products/9.jpeg'
import image10 from 'assets/img/products/10.jpeg'
import image11 from 'assets/img/products/11.jpeg'
import image12 from 'assets/img/products/12.jpeg'
import image13 from 'assets/img/products/13.jpeg'
import image14 from 'assets/img/products/14.jpeg'
import image15 from 'assets/img/products/15.jpeg'
import image16 from 'assets/img/products/16.jpeg'
import image17 from 'assets/img/products/17.jpeg'
import image18 from 'assets/img/products/18.jpeg'
import image19 from 'assets/img/products/19.jpeg'
import image20 from 'assets/img/products/20.jpg'
import image21 from 'assets/img/products/21.jpg'
import image22 from 'assets/img/products/22.jpg'
import image23 from 'assets/img/products/23.jpg'
import image24 from 'assets/img/products/24.jpg'
import image25 from 'assets/img/products/25.jpg'
import image26 from 'assets/img/products/26.jpeg'
import image27 from 'assets/img/products/27.jpeg'






import { grayColor } from 'assets/jss/material-kit-react';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    label: 'Desiccant Bags',
    imgPath:image7,
  },
  {
    label: 'Silica Gel White',
    imgPath:
    image4,
  },
  {
    label: 'Roll on 0.5 gms',
    imgPath:
      image1,
  },
  {
    label: 'Roll on 0.5 gm',
    imgPath:image2,
  },
  {
    label: '1 gms',
    imgPath:image3,
  },

  {
    label: 'Silica Gel Blue',
    imgPath:
    image5,
  },
  {
    label: 'KT Fabric 500 gms',
    imgPath:
      image6,
  },
 
  {
    label: 'Poly Paper 1 gms',
    imgPath:image8,
  },
  {
    label: 'Poly Paper 2 gms',
    imgPath:
    image9,
  },
  {
    label: '2 gms',
    imgPath:
    image10,
  },
  {
    label: '3 gms',
    imgPath:
      image11,
  },
  {
    label: '0.5 gms',
    imgPath:image12,
  },
  {
    label: '5 gms',
    imgPath:image13,
  },
  {
    label: 'KT Fabric 300 gms',
    imgPath:
    image14,
  },
  {
    label: 'Roll on 0.5 gms',
    imgPath:
    image15,
  },
  {
    label: '',
    imgPath:
    image16,
  },
  {
    label: '',
    imgPath:
    image17,
  },
  {
    label: 'Silica Gel Desiccant',
    imgPath:
    image18,
  },
  {
    label: 'Silica Gel Tab Range',
    imgPath:
    image19,
  },
  {
    label: 'Non Woven Fabric/Polly Paper Desiccant',
    imgPath:
    image20,
  },
  {
    label: 'Perfumed Silica Gel',
    imgPath:
    image21,
  },
  {
    label: 'Silica Gel Desiccant Containers',
    imgPath:
    image22,
  },
  {
    label: 'Silica Gel White and Blue',
    imgPath:
    image23,
  },
  
  {
    label: 'Silica Gel Tab Range',
    imgPath:
    image24,
  },
  {
    label: 'Silica Gel Desiccant',
    imgPath:
    image25,
  },
  {
    label: 'Clay Desiccant',
    imgPath:
    image26,
  },
  {
    label: 'Clay Desiccant',
    imgPath:
    image27,
  },
];

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: grayColor,
    fontWeight: "700",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`
  },
  img: {
    height: 255,
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },
}));

function SwipeableTextMobileStepper() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = tutorialSteps.length;

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleStepChange(step) {
    setActiveStep(step);
  }

  return (
    <div className={classes.root}>
<Card className={classes.card}>
      <CardActionArea>
        {/* <CardMedia
          className={classes.media}
          image={image1}
          title="Contemplative Reptile"
        /> */}
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Products Gallery
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
          Here are photos of some products, labeled with size and weight.
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
    </Card>

      <Paper square elevation={0} className={classes.header} >
        {tutorialSteps[activeStep].label}
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {tutorialSteps.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img className={classes.img} src={step.imgPath} alt={step.label} />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            Next
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            Back
          </Button>
        }
      />
    </div>
  );
}

export default SwipeableTextMobileStepper;