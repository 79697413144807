import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import teamStyle from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.jsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.jsx";
class TeamSection extends React.Component {
  render() {
    const { classes } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    return (
      <div >
            <Card>
                 
                 <CardHeader className={classes.cardHeader} >
                   <h3 className={classes.title}> Contact Us</h3>
                   <div className={classes.h4}>
                   <h4 className={classes.h4} >767/881, Street 29/C, Mehmoodabad No. 6, Karachi, Pakistan</h4>
                   <h4 className={classes.h4}>nafeesenter@yahoo.com, nafeesenter@outlook.com</h4>
                   <h4 className={classes.h4}>Phone: (+92) 0300 2118356, FAX: (+92) 021 5803857</h4>
               
                  
                   </div>
                 {/* <List className={classes.list}>
      <ListItem className={classes.listItem}>
                   <Tooltip
          id="instagram-facebook"
          title="Link us on Facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
           <Button
            color="transparent"
            href="https://www.facebook.com/Nafees.silica.gel/"
            target="_blank"
            className={classes.navLink}
          >
            <i className="fab fa-facebook"/>
          </Button> 
        </Tooltip>
        </ListItem>
        </List> */}
                 </CardHeader>
                
               
             </Card>

     
        </div>
     
    );
  }
}

TeamSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(teamStyle)(TeamSection);
