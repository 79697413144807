import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

// import profile from "assets/img/faces/christian.jpg";

// import img1 from "assets/img/products/1.jpeg";
// import img2 from "assets/img/products/2.jpeg";
// import img3 from "assets/img/products/3.jpeg";
// import img4 from "assets/img/products/4.jpeg";
// import img5 from "assets/img/products/5.jpeg";
// import img6 from "assets/img/products/6.jpeg";
// import img7 from "assets/img/products/7.jpeg";
// import img8 from "assets/img/products/8.jpeg";
// import img9 from "assets/img/products/9.jpeg";
// import img10 from "assets/img/products/10.jpeg";
// import Card from "components/Card/Card.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import Slide from "@material-ui/core/Slide";
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContent from "@material-ui/core/DialogContent";
// import IconButton from "@material-ui/core/IconButton";
// import Quote from "components/Typography/Quote";
// @material-ui/icons
// import Close from "@material-ui/icons/Close";
// core components


//import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
// import Button from "components/CustomButtons/Button.jsx";
// import Badge from 'components/Badge/Badge.jsx';
//import { cardTitle } from "assets/jss/material-kit-react.jsx";
import SectionCarousel from "./SectionCarousel.jsx";

// import Timeline from "@material-ui/icons/PhoneIphone

 import Folder from "@material-ui/icons/Folder"
 import Reorder from "@material-ui/icons/Reorder"
 import Home from "@material-ui/icons/Home"
//  import Header from "components/Header/Header.jsx";
//  import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Pets from "@material-ui/icons/Pets"


import ShoppingBasket from "@material-ui/icons/ShoppingBasket"
import Star from "@material-ui/icons/Star";
import PhoneIphone from "@material-ui/icons/PhoneIphone";
import LocalHospital from "@material-ui/icons/LocalHospital";
// nodejs library that concatenates classes
import classNames from "classnames";

// core components
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Typography from '@material-ui/core/Typography';
import { cardTitle, cardLink, cardSubtitle } from "assets/jss/material-kit-react.jsx";
// import { MarkunreadTwoTone } from "@material-ui/icons";
import profilePageStyle from "assets/jss/material-kit-react/views/profilePage.jsx";
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });

// const style = {
//   cardTitle,
// };

const cardImage = {
  

  overflow: 'hidden',
  display: 'flex',
  flexWrap: 'wrap',
}

const style = {
  ...profilePageStyle,
  ...productStyle,
  cardTitle,
  cardLink,
  cardSubtitle,
  cardImage
};




class ProductSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }
  handleClickOpen(modal) {
    var x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }
  render() {
   // const { classes } = this.props;

    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery,classes.imgRaised);


    const dt = new Date();
    return (
      // <div className={classes.section}>
 <div>
        {/* <Header
         
          {...rest}
        /> */}

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>

   
            {/* <h2 className={classes.title}>Our Products</h2> */}
            {/* <Card> */}
          
                    {/* <CardBody> */}
                    <h2 className={classes.title}> About Us</h2>
                    <Typography gutterBottom  variant="h5" component="h1" color="textPrimary">
                    
                    Nafees Enterprises is one of the Leading Importer, Manufacturer and Supplier of Silica gel (Desiccant) Bags in complete range since 1979 throughout Pakistan. 
          
                  
          </Typography>

          <Typography gutterBottom variant="h6" color="textPrimary" component="h4">
          Our respectable customer maintaining their standards to satisfaction and enjoying pleasant business relations. 
          </Typography>

          <Typography gutterBottom variant="h4" style={{ color:"maroon", textAlign:"center"}} component="p">
          Production Capacity 5 Million Pcs/Month 
     
          </Typography> 
                    {/* </CardBody>
                  
                </Card> */}
               

                  </GridItem>
        </GridContainer>
       <hr></hr>

      
       {/* <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={4}>
                              <img
                                alt="..."
                                src={img1}
                                className={navImageClasses}
                              />
                              <img
                                alt="..."
                                src={img2}
                                className={navImageClasses}
                              />
                     
                              <img
                                alt="..."
                                src={img3}
                                className={navImageClasses}
                              />
                              
                              <img
                                alt="..."
                                src={img4}
                                className={navImageClasses}
                              />
                              <img
                                alt="..."
                                src={img5}
                                className={navImageClasses}
                              />
                              <img
                                alt="..."
                                src={img6}
                                className={navImageClasses}
                              />
                             
                              <img
                                alt="..."
                                src={img7}
                                className={navImageClasses}
                              />
                              <img
                                alt="..."
                                src={img8}
                                className={navImageClasses}
                              />
                              
                              <img
                                alt="..."
                                src={img9}
                                className={navImageClasses}
                              />
                            </GridItem>
                          </GridContainer>
   */}
          <GridContainer justify="center">

         

            {/* <GridItem xs={12} sm={12} md={4}> */}
              {/* <InfoArea
                title="Silica Gell"
                description="Mainly use for drying and moisture proof may also be used as catalyst carriers, adsorbents, separators and variable-pressure adsorbents etc."
                icon={ScatterPlot}
                iconColor="info"
                vertical
                
              /> */}
              {/* <Card >
        <CardHeader color="warning">Desiccant Bags</CardHeader>
        <CardBody>
          <h4 className={classes.title}>Silica Gel</h4>
          <p>
          {/* Silica gel mainly use for drying and moisture proof may also be used as catalyst carriers, adsorbents, separators and variable-pressure adsorbents etc. */}
          {/* We packed Silica gel in cotton, non woven fabric and polly paper in plastic container.
          </p>
          <Button style={{backgroundColor: '#3b395f', color: 'white'}} round
          onClick={() => this.handleClickOpen("modal")}>White Silica Gel</Button> */}

{/* <Button color="primary" round
          >Blue Silica Gel</Button>
<Dialog
          classes={{
            root: classes.center,
            paper: classes.modal
          }}
          open={this.state.modal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("modal")}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description">
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}>
            <IconButton
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.handleClose("modal")}>
              <Close className={classes.modalClose} />
            </IconButton>
            <h4 className={classes.modalTitle}>White Silica Gel</h4>
          </DialogTitle>
          <DialogContent
            id="modal-slide-description"
            className={classes.modalBody}>
              <p>
              Silica gel White(TS6) is a highly fine- pored white, semi transparent glass- like hard mass in forms of various sizes( 0.3gms to 1Kg). It has the character of high absorption under low relative humidity and can be used especially as Desiccant, moisture – proof, rust inhibitor as well as carrier of catalysis. Packed in cotton /non woven fabric / Polly paper in plastic container.


              </p>
            <ul>
              <li>Transparent or translucent glass-like pellet or lump.</li>
              <li>Average pore diameter: 2.0-3.0 nm.</li>
              <li>Specific surface area: 650-800 m2/g.</li>
              <li>Specific heat: 0.92 KJ/Kg C.</li>
              <li>Pore volume: 0.35-0.45 ml/g.</li>
              <li>Thermal conductivity: 0.63 KJ/m.hr. C.</li>
              
              </ul>
          </DialogContent>
          {/* <DialogActions
            className={classes.modalFooter +" " +classes.modalFooterCenter}>
            <Button
              onClick={() => this.handleClose("modal")}
            >
              Never Mind
            </Button>
            <Button
              onClick={() => this.handleClose("modal")}
              color="successNoBackground">
              Yes
            </Button>
          </DialogActions> */}
        {/* </Dialog>

        </CardBody> 
      </Card> */} 
            {/* </GridItem>
            <GridItem xs={12} sm={12} md={4}>
            <Card>
        <CardHeader  color="warning"  >Pharma</CardHeader>
        <CardBody>
          <h4 className={classes.title}>Pharma Plastic Products</h4>
          <p>
          Coming soon...
          </p>
          <Button style={{backgroundColor: '#3b395f', color: 'white'}} round >More Details</Button>
        </CardBody>
      </Card>
            </GridItem> */}

            
           
            <GridItem xs={4} sm={4} md={4}  >
          

              {/* <InfoArea
                title="Packaging"
                description="Coming soon 
                .
                "
                icon={Layers}
                iconColor="danger"
                vertical
              /> */}
 <SectionCarousel/>
            </GridItem>

          </GridContainer>

          <hr></hr>

            <GridContainer >
          <GridItem cs={12} sm={12} md={12} >
            <h2 className={classes.title}> What is Silica gel?</h2>
            <h4 className={classes.description} >  Silica gel is a solid form of silicon dioxide, or SiO2, and it is actually more granular and hard than it is a gel. Silica gel is specifically synthesized to be highly porous, which is what gives it its adsorbing capabilities. Additionally, SiO2 is a polar compound, which allows it to preferentially adsorb other polar compounds and making it especially useful in chemical separations.
  
            </h4>
          </GridItem>
          <GridItem cs={12} sm={12} md={12}>
            <h2 className={classes.title}>White Silica Gel</h2>
            <h4 className={classes.description}> Silica gel White(TS6) is a highly fine- pored white, semi transparent glass- like hard mass in forms of various sizes( 0.3gms to 1Kg). It has the character of high absorption under low relative humidity and can be used especially as Desiccant, moisture – proof, rust inhibitor as well as carrier of catalysis. Packed in cotton /non woven fabric / Polly paper in plastic container.
<ul className="d-flex justify-content-start">
  <li>Transparent or translucent glass-like pellet or lump.</li>
  <li>Average pore diameter: 2.0-3.0 nm.</li>
  <li>Specific surface area: 650-800 m2/g.</li>
  <li>Specific heat: 0.92 KJ/Kg C.</li>
  <li>Pore volume: 0.35-0.45 ml/g.</li>
  <li>Thermal conductivity: 0.63 KJ/m.hr. C.</li>

  </ul>






  
            </h4>
          </GridItem>
        </GridContainer>


        





        <GridContainer>
          <GridItem cs={12} sm={12} md={12}>
            <h2 className={classes.title}> Silica Gel Household Usage</h2>
         
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem cs={4} sm={4} md={4}>
          <InfoArea
      title="Protect Your Pills"
      description="A lot of pill bottles come with cotton wadding inside to protect the pills from moisture, but not all.
      Protect your pills from water damage with a gel packet. It also works great if you’re traveling."
      icon={LocalHospital}
      iconColor="rose"
    />
          </GridItem>
          <GridItem cs={4} sm={4} md={4}>
          <InfoArea
      title="Dry Out Your Phone"
      description="Getting your phone wet is a major hassle, but it happens.You can also use this if you’re going to be in a damp environment and want to keep moisture away from your phone."
      icon={PhoneIphone}
      iconColor="rose"
    />
          </GridItem>
          <GridItem cs={4} sm={4} md={4}>
          <InfoArea
      title="Keep Jewelry Bright And Shiny"
      description="Keep a silica gel packet in your jewelry box to keep moisture away from your jewelry.
      
      Place some gel packets in the bottom of the jewelry box to keep the moisture out."
      icon={Star}
      iconColor="rose"
    />
          </GridItem>


          <GridItem cs={4} sm={4} md={4}>
          <InfoArea
      title="Banish Odors From Your Gym Bag"
      description="Drop a packet or two into your bag and forget them. They’ll quietly pull the moisture away and leave your items fresh. They work in lockers, too."
      icon={ShoppingBasket}
      iconColor="rose"
    />
          </GridItem>
          <GridItem cs={4} sm={4} md={4}>
          <InfoArea
      title="Keep Dry Goods"
      description="Silica gel is inedible, but it can help keep foods like dried herbs, bread crumbs, crackers, and anything else that should be kept as crispy as possible from getting soggy."
      icon={ShoppingBasket}
      iconColor="rose"
    />
          </GridItem>
          <GridItem cs={4} sm={4} md={4}>
          <InfoArea
      title="Freshen Your Home"
      description="Tear open a silica gel packet or two (or more, depending on the size of the place you’re trying to scent) and pour the beads into a small bowl or container."
      icon={Home}
      iconColor="rose"
    />

          </GridItem>

          <GridItem cs={4} sm={4} md={4}>
          <InfoArea
      title="Get More Life Out Of Pet Food"
      description="Just like they work for human food, silica gel packets work for pet food, keeping dry treats and kibble from getting soggy and stale."
      icon={Pets}
      iconColor="rose"
    />
          </GridItem>

          

 <GridItem cs={4} sm={4} md={4}>
          <InfoArea
      title="Freshen Stale Drawers"
      description="A Silica gel packet in a drawer can combat the musty odor that sometimes appears, especially in drawers full of seasonal clothing that goes unused half the year."
      icon={Reorder}
      iconColor="rose"
    />
    
          </GridItem>
          <GridItem cs={4} sm={4} md={4}>
          <InfoArea
      title="Prevent Damage To Documents"
      description="Keep your files and memories safe by putting gel packets into folders and boxes, especially ones that will be shut away in storage for a long time."
      icon={Folder}
      iconColor="rose"
    />
          </GridItem>

        </GridContainer>

        </div>
      
    );
  }
}

ProductSection.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(ProductSection);
